.change-password {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.change-password .div-2 {
  background-color: #ffffff;
  height: 1400px;
  position: relative;
  width: 1640px;
}

.change-password .middle-section {
  align-items: flex-start;
  display: flex;
  height: 1300px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 100px;
  width: 1640px;
}

.change-password .left-menu-instance {
  top: unset !important;
  width: 240px !important;
}

.change-password .body-container {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  padding: 100px 200px;
  position: relative;
}

.change-password .login-area {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 525px;
  justify-content: center;
  padding: 20px 10px;
  position: relative;
  width: 100%;
}

.change-password .login-text {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  position: relative;
  width: 100%;
}

.change-password .login-title {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: #ffffff;
  display: flex;
  gap: 10px;
  height: 46px;
  justify-content: center;
  padding: 7.5px 0px;
  position: relative;
  width: 100%;
}

.change-password .text-wrapper-6 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.change-password .login-sub-title {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 44px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 100%;
}

.change-password .p {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -1.00px;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.change-password .form-login {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  justify-content: center;
  padding: 20px 10px;
  position: relative;
  width: 100%;
}

.change-password .div-wrapper-2 {
  align-items: center;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  gap: 10px;
  height: 48px;
  justify-content: center;
  padding: 14.5px 0px;
  position: relative;
  width: 380px;
}

.change-password .text-wrapper-7 {
  color: #000000;
  flex: 1;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.change-password .change-password-btn-wrapper {
  align-items: center;
  background-color: #163e64;
  border: 1px solid;
  border-color: #bcbcbc;
  display: flex;
  gap: 10px;
  height: 48px;
  justify-content: center;
  padding: 14.5px 0px;
  position: relative;
  width: 380px;
}

.change-password .change-password-btn {
  color: #ffffff;
  flex: 1;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.change-password .menu-section {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 40px;
  width: 1640px;
}

.change-password .main-logo-instance {
  top: unset !important;
}

.change-password .right-menu-instance {
  flex: 0 0 auto !important;
  left: unset !important;
  top: unset !important;
}
